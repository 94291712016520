"use strict";
 
const Degradado = function() {
    return {
        ocultarDegradado: function(el) {
            var degradado = el.closest('.degradado');
            var cuerpo = el.closest('.cuerpo');
            degradado.style.display = "none";
            cuerpo.classList.remove("noticia-cortada");

            var path = window.location.pathname;
            path = path.substring(1);

            $.ajax({
                url : '/incrementar-contador',
                type : "GET",
                data : { 'slug': path }
            });

        },
    };
} ();

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Degradado;
}