var menuToggle = document.getElementById('menu-toggle');
var btnAbrir = document.getElementById('abrir-menu-toggle');
var btnAbrirSticky = document.getElementById('abrir-menu-toggle-sticky');
var btnCerrar = document.getElementById('cerrar-menu-toggle');

var superdestacada = document.getElementById('bloque-noticias-a0');
var header = document.getElementById('header');

if (menuToggle) {

    setTimeout(function() {
        $("#menu-toggle").removeClass("d-none");
    }, 1000);

    btnAbrir.addEventListener('click', function (e) {
        e.stopPropagation();
        abrirMenu(menuToggle);
    });

    btnAbrirSticky.addEventListener('click', function (e) {
        e.stopPropagation();
        abrirMenu(menuToggle);
    });

    btnCerrar.addEventListener('click', function () {
        cerrarMenu(menuToggle);
    });

    menuToggle.addEventListener('click', function (e) {
        e.stopPropagation();
    });

    window.addEventListener('click', function(e) {
        if (menuToggle && "visible" === menuToggle.style.visibility) {
            cerrarMenu(menuToggle);
        }
    });

    window.addEventListener('click', function(e){
        if (!menuToggle.contains(e.target)){
            cerrarMenu(menuToggle);
        }
    });
}

function abrirMenu(menu) {
    menu.classList.add("is-visible");
}

function cerrarMenu(menu) {
    menu.classList.remove("is-visible");
}

if (superdestacada) {
    header.classList.add("superdestacada");
    var heightSuperdestacada = $("#bloque-noticias-a0").height() - $("#header").height();
    $(".logo-main a img").attr('src', '/build/images/logo_reason_why_blanco.svg');
    $("#bloque-noticias-a0-height").css("height", heightSuperdestacada);
}

$(window).resize(function(){
    if (superdestacada) {
        var heightSuperdestacada = $("#bloque-noticias-a0").height() - $("#header").height();    
        $("#bloque-noticias-a0-height").css("height", heightSuperdestacada);
    }
});

$(document).ready(function(){
    if ($("#menu > ul").width() > 800) {
        $("#menu > ul").css( "font-size", "14px" );
        $("#menu > label").css( "font-size", "14px" );
    }
});