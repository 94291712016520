// const buscador = document.getElementById('buscador');
const buscadores = document.getElementsByClassName('buscador-form');

for (let i = 0; i < buscadores.length; i++) {
    buscadores[i].addEventListener('submit', function (e) {
        e.preventDefault();
        var busqueda = buscadores[i].getElementsByClassName('busqueda-input');
        window.location.href = buscadores[i].action + "/" + busqueda[0].value;
    });
}

$('#busqueda').focus(function() {
    $('#menu > ul').addClass('buscador-on');
    $('#menu .icon-bar').addClass('buscador-on');
});
$('#busqueda').blur(function() {
    $('#menu > ul').removeClass('buscador-on');
    $('#menu .icon-bar').removeClass('buscador-on');
});