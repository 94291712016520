const headerSticky = document.getElementById('header-sticky');

const header = document.getElementById('header');
const largueroSticky = document.getElementsByClassName('publi-noticia-LARGUEROSTICKY');
const portada = document.getElementsByClassName('portada');
let heightLarguero = 0;

if (largueroSticky[0]) {
    heightLarguero = largueroSticky[0].offsetHeight;
}

if (headerSticky) {
    window.addEventListener('scroll', function () {
        let getScrollposition = window.scrollY;

        if (getScrollposition > 75) {
            headerSticky.classList.add("is-visible");
        } else {
            headerSticky.classList.remove("is-visible");
        }
    });
}

if (largueroSticky[0] && portada[0]) {
    headerSticky.classList.add("d-none");
    header.style.marginTop = heightLarguero+"px";
    window.addEventListener('resize', function () {
        heightLarguero = largueroSticky[0].offsetHeight;
        header.style.marginTop = heightLarguero+"px";
    });
}