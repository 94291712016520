const heightBase = "60px";
const leer = document.getElementById("leer");

if (leer) {
    var idEl = leer.hash.substring(1);
    console.log(idEl);
    var el = document.getElementById(idEl);
    var heightEl = el.offsetHeight+"px";

    console.log(heightEl);

    el.style.height = heightBase;

    leer.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (el.classList.contains("show")) {
            el.classList.remove("show");
            el.style.height = heightBase;
            this.innerHTML = "+ Leer Más";
        } else {
            el.classList.add("show");
            el.style.height = heightEl;
            this.innerHTML = "- Leer Menos";
        }
    });
}
