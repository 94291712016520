const destacadas = $('.destacada');

if (destacadas.length > 0) {
    for (let i = 0; i < destacadas.length; i++) {
        let entradilla = destacadas[i].getElementsByClassName('entradilla')[0];
        let heightEntradilla = entradilla.offsetHeight;
        entradilla.style.height = 0;

        if ( window.matchMedia("(min-width: 990px)").matches ) {
            destacadas[i].addEventListener('mouseover', function() {
                entradilla.style.height = heightEntradilla+"px";
                entradilla.style.visibility = "visible";
            });
        
            destacadas[i].addEventListener('mouseout', function() {
                entradilla.style.height = 0;
                entradilla.style.visibility = "hidden";
            });
        }
    }
}
