"use strict";
 
const IniciarTwitter = function() {

  $('.notwitter').each(function() {       
    $(this).remove();
  });

  const observer = lozad('.lozad', {
    load: el => {
     
      const bq = document.createElement('blockquote');
      bq.className = 'twitter-tweet';

      const p = document.createElement('p');
      p.lang = 'ja';
      p.dir = 'ltr';
      bq.appendChild(p);

      const twitterSrc = el.dataset.twitterSrc;
      const a = document.createElement('a');
      a.href = twitterSrc;
      bq.appendChild(a);

      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.charset = 'utf-8';
      script.async = true;

      el.insertAdjacentElement('beforeEnd', bq);
      el.insertAdjacentElement('beforeEnd', script);
    }
  });
  observer.observe();

}
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = IniciarTwitter;
}