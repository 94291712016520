"use strict";
 
const IniciarGaleria = function(dataID) {

  if ( typeof lozad !== 'undefined' ) {

    const observer2 = lozad('.galeria-container', {
      load: el => {
        $('.slider-item').each(function() {
          if ( !$(this).hasClass('pintado') ) {
            $(this).remove();
          }
        });

        pintarGaleria(el, dataID);
      }
    });

    observer2.observe();
    
  }
   
  var pintarGaleria = function(el, dataID) { 
    $.ajax({
      url : '/galeria/datos',
      type : "GET",
      data: { galeria: el.id },
      success : function(datos) {
      
        datos.imagenes.forEach(function(item) {
          const sliderItem = document.createElement('div');
          sliderItem.className = 'slider-item pintado';

          const figure = document.createElement('figure');
          figure.className = 'image'; 
          sliderItem.appendChild(figure);

          const img = document.createElement('img'); 
          img.src = '/' + item.path; 
          figure.appendChild(img);

          const pie = document.createElement('p');  
          pie.className = 'pie-foto'; 

          let textoPie = item.pie;

          if (textoPie !=  null){
            const textnode = document.createTextNode(textoPie);
            pie.appendChild(textnode);
            sliderItem.appendChild(pie);
          }

          el.insertAdjacentElement('beforeEnd', sliderItem); 
        })  
        
        iniciarSlick(el, dataID);
      } 
    });
  }

  var iniciarSlick = function(el, dataID) { 
    let slick = '#'+ dataID + ' #' + el.id;

    $(slick).slick({
      dots: true,
      infinite: true,
      nextArrow: '<i class="fas fa-angle-right slick-next"></i>',
      prevArrow: '<i class="fas fa-angle-left slick-prev"></i>',
      adaptiveHeight: false,
    });

  }

}
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = IniciarGaleria;
}
