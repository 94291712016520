const headerMultiple = document.getElementById('header-multiple');

if (headerMultiple !== null) {
    const anio = headerMultiple.dataset.anio;

    const inputPortadaSwitch = document.getElementById('input-portada-siwtch');
    const textPortadaSwitch = document.getElementById('text-portada-siwtch');
    const portada = document.getElementById('portada');
    const resumen = document.getElementById('resumen-'+anio);
    const switchResumen = document.getElementById('switch-resumen');
    const menu = document.getElementById('menu');
    //const alertaPortada = document.getElementById('alerta-portada');

    const switchResumenSticky = document.getElementById('switch-resumen-sticky');
    const inputPortadaSwitchSticky = document.getElementById('input-portada-siwtch-sticky');
    const textPortadaSwitchSticky = document.getElementById('text-portada-siwtch-sticky');

    if (inputPortadaSwitch) {
        inputPortadaSwitch.onclick = function() {
            if (!inputPortadaSwitch.checked) {
                resumen.classList.remove('show');

                if ( $("header").hasClass("superdestacada") ) {
                    $(".logo-main a img").attr('src', '/build/images/logo_reason_why_blanco.svg');
                }

                textPortadaSwitch.innerHTML = 'Ver portada resumen '+anio;
                switchResumen.style.transform = 'translateX(19px)';
                textPortadaSwitchSticky.innerHTML = 'Ver portada resumen '+anio;
                switchResumenSticky.style.transform = 'translateX(19px)';

                setTimeout(function() {
                    resumen.style.display = 'none';
                    portada.style.display = 'block';
                    menu.style.display = 'flex';
                    // alertaPortada.style.display = 'block';
                }, 200);

                setTimeout(function() {
                    portada.classList.add('show');
                    var heightSuperdestacada = $("#bloque-noticias-a0").height() - $("#header").height();
                    $("#bloque-noticias-a0-height").css("height", heightSuperdestacada);
                }, 300);

            } else {
                portada.classList.remove('show');
                $(".logo-main a img").attr('src', '/build/images/logotipo_reason_why.svg');

                textPortadaSwitch.innerHTML = 'Volver a la portada de hoy';
                switchResumen.style.transform = 'translateX(0px)';
                textPortadaSwitchSticky.innerHTML = 'Volver a la portada de hoy';
                switchResumenSticky.style.transform = 'translateX(0px)';

                setTimeout(function() {
                    portada.style.display = 'none';
                    resumen.style.display = 'block';
                    menu.style.display = 'none';
                    // alertaPortada.style.display = 'none';
                }, 200);

                setTimeout(function() {
                    resumen.classList.add('show');
                }, 300);
            }
        }

        if (inputPortadaSwitch.checked) {
            menu.style.display = 'none';
            $(".logo-main a img").attr('src', '/build/images/logotipo_reason_why.svg');
            // alertaPortada.style.display = 'none';
            switchResumenSticky.style.transform = 'translateX(0px)';
            switchResumen.style.transform = 'translateX(0px)';
        }
    }

    if (inputPortadaSwitchSticky) {
        inputPortadaSwitchSticky.onclick = function() {
            inputPortadaSwitch.click();
            if (!inputPortadaSwitchSticky.checked) {
                if($("header").hasClass("superdestacada")) {
                    $(".logo-main a img").attr('src', '/build/images/logo_reason_why_blanco.svg');
                }

                textPortadaSwitchSticky.innerHTML = 'Ver portada resumen '+anio;
                switchResumenSticky.style.transform = 'translateX(19px)';

                const heightSuperdestacada = $("#bloque-noticias-a0").height() - $("#header").height();
                $("#bloque-noticias-a0-height").css("height", heightSuperdestacada);

            } else {
                $(".logo-main a img").attr('src', '/build/images/logotipo_reason_why.svg');
                textPortadaSwitchSticky.innerHTML = 'Volver a la portada de hoy';
                switchResumenSticky.style.transform = 'translateX(0px)';
            }

            $("html, body").animate({ scrollTop: 0 }, 600);
        }
    }

    $("#input-portada-siwtch").change(function() {
        $("#input-portada-siwtch-sticky").prop("checked", this.checked);
    });
}